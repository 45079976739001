<template>
  <div class="page">
    <div class="container">
      <div class="section">
        <h3>{{ $t('generalLedgerList.listTitle') }}</h3>
        <div class="list-content">
          <b-table
            :data="accounts"
            :loading="loading"
            :selected.sync="selectedRow"
            striped
            sticky-header
            narrowed
          >
            <template slot="empty">
              <empty-list v-if="!loading" />
            </template>

            <b-table-column
              label="Account #"
              field="accountNumber"
              numeric
              sortable
              centered
              searchable
              v-slot="props"
            >
              {{ props.row.accountNumber }}
            </b-table-column>

            <b-table-column
              label="Sub-account #"
              field="subAccountNumber"
              numeric
              sortable
              centered
              searchable
              v-slot="props"
            >
              {{ props.row.subAccountNumber }}
            </b-table-column>

            <b-table-column
              field="accountType"
              label="Account Type"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.accountType }}
            </b-table-column>

            <b-table-column
              field="description"
              label="Account Description"
              sortable
              searchable
              v-slot="props"
            >
              {{ props.row.description }}
            </b-table-column>
            <!-- 
              <b-table-column field="address.addressOne" label="Address">
                {{ row.address.addressOne }} {{ row.address.addressTwo }}
              </b-table-column>
              
              <b-table-column label="Status"></b-table-column> -->

            <b-table-column v-slot="props">
              <router-link
                class="button is-small is-outline is-rounded"
                :to="{ name: 'account', params: { id: props.row.accountID } }"
              >
                Details
              </router-link>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import EmptyList from '@/components/EmptyList'

export default {
  components: { EmptyList },

  data: () => ({
    loading: false,
    selectedRow: null
  }),

  computed: {
    ...mapState({
      accounts: state => state.hoa.glAccounts,
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    hoaId() {
      this.reload()
    },

    // go to details page when selected
    selectedRow(row) {
      this.$router.push({ name: 'account', params: { id: row.accountID } })
    }
  },

  mounted() {
    this.reload()
  },

  methods: {
    ...mapActions('hoa', ['loadGLAccounts']),

    async reload() {
      this.loading = true
      await this.loadGLAccounts()
      this.loading = false
    }
  },

  i18n: {
    messages: {
      en: { generalLedgerList: { listTitle: 'Chart of Accounts' } }
    }
  }
}
</script>
